import React, { useState } from "react";
import "./App.css";
import pfp from "./images/pfp.jpeg";

function App() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    setLoading(true);
    setErrorMessage("");

    // Simulate network request
    setTimeout(() => {
      setLoading(false);
      setErrorMessage("Access denied");
    }, 3000);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="middle-content">
          <img src={pfp} className="App-logo" alt="logo" />
          <h3>HARDWORKERS</h3>
          <p className="sub"> v2.0 coming 2024</p>
          <button
            onClick={handleClick}
            className="button-style"
            disabled={loading}
          >
            {loading ? "Loading..." : "Get access"}
          </button>
        </div>
        <div className="bottom-content">
          <div className="button-container"></div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </header>
    </div>
  );
}

export default App;
